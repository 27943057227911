import React, { useEffect } from 'react';
import { Form, Input, InputNumber, Button, Select, List } from 'antd';
import { createSubscription } from "../../features/subscription/subscriptionSlice";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message as msg } from 'antd';
const { Option } = Select;

const CreateSubscription = () => {
  const [messageApi, contextHolder] = msg.useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [features, setFeatures] = React.useState([]);
  const [featureInput, setFeatureInput] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("subscription");
  const { isError, creating, message, isCreateSuccess } = useSelector((state) => state.subscription);

  const onFinish = (values) => {
    const data = { ...values, features };
    dispatch(createSubscription(data));
  };

  const addFeature = () => {
    if (featureInput) {
      setFeatures([...features, featureInput]);
      setFeatureInput('');
    }
  };

  const removeFeature = (index) => {
    const newFeatures = features.filter((_, i) => i !== index);
    setFeatures(newFeatures);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addFeature();
    }
  };

  useEffect(() => {
    if (isError && message) {
      messageApi.open({
        type: 'error',
        content: message,
      });
    }

    if (isCreateSuccess) {
      messageApi.open({
        type: 'success',
        content: "Setting is updated successfully",
      });
    }

    if (isCreateSuccess) {
      navigate('/admin/subscription');
    }
  }, [isError, message, isCreateSuccess, messageApi, navigate]);

  return (
    <div>
      {contextHolder}
      <h3 className="mb-4 title">Subscription Plans</h3>
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ period: 'day', status: 1, type: 'subscription'}}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product ID"
          name="product_id"
          rules={[{ required: true, message: 'Please input the product ID!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: 'Please input the price!' }]}
        >
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, message: "Please select the type!" }]}
        >
          <Select onChange={(value) => setSelectedType(value)}>
            <Option value="subscription">Subscription</Option>
            <Option value="instant_pay">Instant Pay</Option>
            <Option value="diamond_pass">Diamond Pass</Option>
          </Select>
        </Form.Item>

        {selectedType === "subscription" && (
          <Form.Item
            label="Period"
            name="period"
            rules={[{ required: true, message: 'Please select the period!' }]}
          >
            <Select>
              <Option value="day">Daily</Option>
              <Option value="week">Weekly</Option>
              <Option value="month">Monthly</Option>
              <Option value="3months">Every 3 months</Option>
              <Option value="6months">Every 6 months</Option>
              <Option value="yearly">Yearly</Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please input the description!' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item label="Features">
          <Input
            value={featureInput}
            onChange={(e) => setFeatureInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Enter a feature and press Enter"
          />
          <Button onClick={addFeature} type="primary" style={{ marginTop: '10px' }}>
            Add Feature
          </Button>
          <List
            size="small"
            bordered
            dataSource={features}
            renderItem={(item, index) => (
              <List.Item>
                {item}
                <Button type="link" onClick={(e) => {e.preventDefault(); removeFeature(index);}}>Remove</Button>
              </List.Item>
            )}
            style={{ marginTop: '10px', padding: '10px' }}
          />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select>
            <Option value="1">Active</Option>
            <Option value="0">Inactive</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="swipeLimit"
          name="swipeLimit"
          rules={[{ required: true, message: 'Please right swipe limit!' }]}
        >
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Right swipe limit duration (hr)"
          name="swipeLimitDuration"
          rules={[{ required: true, message: 'Please right swipe limit duration !' }]}
        >
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={creating}>
            {creating? 'Submitting...' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateSubscription;
