import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authServices";

const getUserfromLocalStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const getTokenStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  user: getUserfromLocalStorage,
  orders: [],
  isError: false,
  isLoading: false,
  message: "",
  isLogin: getTokenStorage ? true : false,
  token: getTokenStorage,
};
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      return await authService.login(userData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    const response = await authService.logout();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});


export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (buildeer) => {
    buildeer
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.user = action.payload.data.user;
        state.token = action.payload.data.access_token;
        state.message = "success";
        state.isLogin = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload.response.data.message;
        state.isLoading = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLogin = false;
        state.user = null;
        state.token = null;
      });
  },
});

export default authSlice.reducer;
