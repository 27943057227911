import axiosInstance from "./../../utils/axiosInstance";

const login = async (user) => {
  const response = await axiosInstance.post('auth/login', user);
  if (response.data && response.data.data && response.data.data.access_token) {
    localStorage.setItem("token", response.data.data.access_token);
  }
  if (response.data && response.data.data && response.data.data.user) {
    localStorage.setItem("user", JSON.stringify(response.data.data.user));
  }
  return response.data;
};

const logout = async () => {
  // const response = await axiosInstance.get('auth/logout');
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  return;
  // return response.data;
};


const authService = {
  logout,
  login,
};

export default authService;
