import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import customerReducer from "../features/cutomers/customerSlice";
import settingReducer from "../features/setting/settingSlice";
import subscriptionReducer from "../features/subscription/subscriptionSlice";
import diamondPassUserReducer from "../features/cutomers/diamondPassUserSlice";
import normalUserReducer from "../features/cutomers/normalUserSlice";
import notificationReducer from "../features/notification/notificationSlice";

import notifictionLogReducer from '../features/notificationLog/notificationLogSlice';
import reportUserReducer from "../features/reportUser/reportUserSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    setting: settingReducer,
    subscription: subscriptionReducer,
    reportUser: reportUserReducer,
    diamondPassUser: diamondPassUserReducer,
    mormalUsers: normalUserReducer,
    notification: notificationReducer,
    notificationLog: notifictionLogReducer,
  },
});
