import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscriptionService from "./subscriptionService";

export const getSubscriptions = createAsyncThunk(
  "subscription/get-subscriptions",
  async (params, thunkAPI) => {
    try {
      return await subscriptionService.getSubscriptions(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSubscription = createAsyncThunk(
  "subscription/get-subscription",
  async (data, thunkAPI) => {
    try {
      return await subscriptionService.getSubscription(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "subscription/create-subscription",
  async (data, thunkAPI) => {
    try {
      return await subscriptionService.createSubscription(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscription/update-subscription",
  async (data, thunkAPI) => {
    try {
      return await subscriptionService.updateSubscription(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSubscriptionStatus = createAsyncThunk(
  "customer/update-subscription-status",
  async (data, thunkAPI) => {
    try {
      return await subscriptionService.updateSubscriptionStatus(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  subscriptions: [],
  total: 0,
  subscription: {},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
  updating: false,
  isUpdateSuccess: false,
  creating: false,
  isCreateSuccess: false,
  isUpdateStatusSuccess: false,
};
export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    initSubscription: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
      state.updating = false;
      state.isUpdateSuccess = false;
      state.creating = false;
      state.isCreateSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.subscriptions = action.payload.subscriptions;
        state.total = action.payload.subscriptionCount;
        state.isCreateSuccess = false;
        state.isUpdateSuccess = false;
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createSubscription.pending, (state, action) => {
        state.creating = true;
        state.isCreateSuccess = false;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload.response.data.message[0];
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.creating = false;
        state.isCreateSuccess = true;
      })
      .addCase(updateSubscription.pending, (state, action) => {
        state.updating = true;
        state.isUpdateSuccess = false;
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload.response.data.message[0];
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.updating = false;
        state.isUpdateSuccess = true;
      })
      .addCase(updateSubscriptionStatus.pending, (state, action) => {
        state.updating = true;
        state.isUpdateStatusSuccess = false;
      })
      .addCase(updateSubscriptionStatus.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload.response.data.message[0];
      })
      .addCase(updateSubscriptionStatus.fulfilled, (state, action) => {
        state.updating = false;
        state.isUpdateStatusSuccess = true;
        const index = state.subscriptions.findIndex(
          (subscription) => subscription._id === action.payload._id
        );
        state.subscriptions[index].status = action.payload.status;
      })
      .addCase(getSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.subscription = action.payload;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
  },
});
export default subscriptionSlice.reducer;
export const { initSubscription } = subscriptionSlice.actions;